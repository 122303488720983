import React from 'react'
import useStore from '../../../hooks/useStore'
import Api from '../../../session/Api'
import UIUtil from '../../../util/UIUtil'

// updateCartItem(itemId, quantity, removing, silent, skipLoading, onDone) {
//     if (!skipLoading) {
//         this.setState({loadingCartUpdate: true})
//     }
//     Api.updateCartItem({ itemId, quantity, removing }, response => {
//         this.onItemUpdated(response, removing, silent, skipLoading, onDone);
//     })
// }

export default (sessionStore) => {
    const [loading, setLoading] = useStore(sessionStore, "loading")
    const setSession = session => sessionStore.setObject(session)
    const getParentId = () => sessionStore.get("parentId")

    const setInventoryReadyDate = (readyDate, onDone, onSuccess) => {
        setLoading(true);
        Api.setPosInventoryReadyDate(getParentId(), readyDate, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                setSession(response.payload)
                onSuccess?.()
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
            if (onDone) {
                onDone()
            }
        })
    }

    const setInventoryStatus = (status, onDone, onSuccess) => {
        setLoading(true);
        Api.setPosInventoryStatus(getParentId(), status, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                setSession(response.payload)
                onSuccess?.()
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
            if (onDone) {
                onDone()
            }
        })
    }

    const setInventoryNote = (inventoryNote, onDone, onSuccess) => {
        setLoading(true);
        Api.setPosInventoryNote(getParentId(), inventoryNote, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                setSession(response.payload)
                onSuccess?.()
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
            if (onDone) {
                onDone()
            }
        })
    }

    const setInventoryAllocate = (locId, onDone, onSuccess) => {
        setLoading(true);
        Api.setPosInventoryAllocate(getParentId(), locId, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                setSession(response.payload)
                onSuccess?.()
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
            if (onDone) {
                onDone()
            }
        })
    }

    const setMemo = (memo, onDone, onSuccess) => {
        setLoading(true);
        Api.setPosMemo(getParentId(), memo, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                setSession(response.payload)
                onSuccess?.()
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
            if (onDone) {
                onDone()
            }
        })
    }

    const setNoTax = (noTax, onDone) => {
        if (loading) {
            return;
        }

        setLoading(true);
        Api.setPosNoTax(getParentId(), noTax, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                setSession(response.payload)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
            if (onDone) {
                onDone()
            }
        })
    }

    const setSalespersonId = (salespersonId, onDone) => {
        setLoading(true);
        Api.setPosSalesperson2(getParentId(), salespersonId, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                setSession(response.payload)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
            if (onDone) {
                onDone()
            }
        })
    }

    const updateCartItem = (itemId, quantity, removing, onDone) => {
        setLoading(true)
        Api.updateCartItem2({ itemId, quantity, removing }, getParentId(), response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                setSession(response.payload)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
            if (onDone) {
                onDone()
            }
        })
    }

    const updateContinuousCartItem = (itemId, quantity, displayUom, onDone) => {
        setLoading(true)
        Api.updateCartItem2({ itemId, quantity, continuous: true, displayUom }, getParentId(), response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                setSession(response.payload)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
            if (onDone) {
                onDone()
            }
        })
    }

    const setCartItemCustomPrice = (cartItemId, customPrice, clearanceCode, onDone, onRequireClearance, removeDialog) => {
        setLoading(true)
        Api.setCartItemCustomPrice2(getParentId(), cartItemId, customPrice, clearanceCode, response => {
            setLoading(false)

            if (response.status === true) {
                if (response.payload === "requires_clearance_code") {
                    onRequireClearance();
                    return;
                }


                setSession(response.payload)
                UIUtil.showSuccess();
                removeDialog();
            } else {
                UIUtil.showError(response.message);
            }
            onDone()
        })
    }

    const addDiscount = (amount, mode, onDone, onSuccess) => {
        Api.addFreeFormDiscount2(getParentId(), amount, undefined, mode, undefined, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                setSession(response.payload)
                onSuccess?.()
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false)
            if (onDone) {
                onDone()
            }
        })
    }

    const removeDiscount = (discountId, onFail, onSuccess) => {
        setLoading(true);
        Api.removeDiscount(getParentId(), discountId, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                setSession(response.payload)
                onSuccess?.()
            } else {
                UIUtil.showError(response.message)
                onFail?.();
            }
            setLoading(false)
        })
    }

    return {
        loading,
        updateCartItem,
        updateContinuousCartItem,
        setCartItemCustomPrice,
        setSalespersonId,
        setNoTax,
        setMemo,
        setInventoryStatus,
        setInventoryReadyDate,
        setInventoryNote,
        setInventoryAllocate,

        addDiscount,
        removeDiscount,

        allowCustomPrice: sessionStore.get("allowCustomPrice") === true
    }
}