
export const OBJECT_TYPE_ACCOUNT = 0;
export const OBJECT_TYPE_PRODUCT_CATEGORY = 1;
export const OBJECT_TYPE_STORE = 2;
export const OBJECT_TYPE_TERMINAL = 3;
export const OBJECT_TYPE_PRODUCT = 4;
export const OBJECT_TYPE_PRODUCT_BUNDLE = 5;
export const OBJECT_TYPE_LOYALTY_CARD = 6;
export const OBJECT_TYPE_LOYALTY_CARD_ISSUE = 7;
export const OBJECT_TYPE_CUSTOMER = 8;
export const OBJECT_TYPE_SUPPLIER = 9;
export const OBJECT_TYPE_CUSTOMER_GROUP = 10;
export const OBJECT_TYPE_COUPON = 11;
export const OBJECT_TYPE_DISCOUNT = 12;
export const OBJECT_TYPE_CONTACT = 13;
export const OBJECT_TYPE_WAREHOUSE = 14;
export const OBJECT_TYPE_GROUP_PRICE_OVERRIDE = 15;
export const OBJECT_TYPE_PRICE_OVERRIDE = 16;
export const OBJECT_TYPE_ACCOUNT_LEDGER_GROUP = 17;
export const OBJECT_TYPE_ACCOUNT_LEDGER = 18;
export const OBJECT_TYPE_TRANSACTION = 19;
export const OBJECT_TYPE_STOCK_FLOW = 20;
export const OBJECT_TYPE_JOURNAL_ENTRY = 21;
export const OBJECT_TYPE_POS_SESSION = 22;
export const OBJECT_TYPE_TAX = 23;
export const OBJECT_TYPE_CHEQUE = 24;
export const OBJECT_TYPE_SUPPORTED_CURRENCY = 25;
export const OBJECT_TYPE_PRODUCT_DEPARTMENT = 26;
export const OBJECT_TYPE_PRODUCT_SUBCATEGORY = 27;
export const OBJECT_TYPE_PRODUCT_BRAND = 28;
export const OBJECT_TYPE_TEMPLATE = 29;
export const OBJECT_TYPE_PRODUCT_BUNDLE_ITEM = 30;
export const OBJECT_TYPE_MOBILE_UNIT = 31;
export const OBJECT_TYPE_MEASUREMENT_TYPE = 32;
export const OBJECT_TYPE_SALES_CART_TYPE = 33;
export const OBJECT_TYPE_CRV = 34;
export const OBJECT_TYPE_SALES_CART = 35;
export const OBJECT_TYPE_SALES_CART_WORK = 36;
export const OBJECT_TYPE_PACKAGE_UNIT = 37;
export const OBJECT_TYPE_PRODUCT_ATTRIBUTES = 38;
export const OBJECT_TYPE_PRODUCT_GROUP = 39;
export const OBJECT_TYPE_SALES_ORDER_STATE = 40;
export const OBJECT_TYPE_ONLINE_STORE_PAYMENT_METHOD = 41;
export const OBJECT_TYPE_ONLINE_STORE_CUSTOM_PAGE = 42;
export const OBJECT_TYPE_BLOG_CATEGORY = 43;
export const OBJECT_TYPE_BLOG_POST = 44;
export const OBJECT_TYPE_SALES_ORDER = 45;
export const OBJECT_NEWSLETTER_SUBSCRIPTION = 46;
export const OBJECT_TYPE_SLIDE = 47;
export const OBJECT_TYPE_DESIGNATION = 48;
export const OBJECT_TYPE_LEAVE_TYPE = 49;
export const OBJECT_TYPE_PAY_HEAD = 50;
export const OBJECT_TYPE_SALARY_PACKAGE = 51;
export const OBJECT_TYPE_EMPLOYEE = 52;
export const OBJECT_TYPE_LEAVE = 53;
export const OBJECT_TYPE_VENDOR = 54;
export const OBJECT_TYPE_CUSTOM_PRICE = 55;
export const OBJECT_TYPE_PAGE_HEAD = 56;
export const OBJECT_TYPE_MOBILE_UNIT_DRIVER_ASSIGNATION = 57;
export const OBJECT_TYPE_ALLOWED_PRODUCT = 58;
export const OBJECT_TYPE_STOCK_REQUEST = 59;
export const OBJECT_TYPE_VENUE = 60;
export const OBJECT_TYPE_INVOICE_DOCUMENT = 61;
export const OBJECT_TYPE_BILL = 62;
export const OBJECT_TYPE_GALLERY_POST = 63;
export const OBJECT_TYPE_TESTIMONY_POST = 64;
export const OBJECT_TYPE_PURCHASE = 65;
export const OBJECT_TYPE_SERVICE_DEPARTMENT = 66;
export const OBJECT_TYPE_SERVICE_CATEGORY = 67;
export const OBJECT_TYPE_SERVICE_SUBCATEGORY = 68;
export const OBJECT_TYPE_SERVICE = 69;
export const OBJECT_TYPE_SERVICE_PACKAGE = 70;
export const OBJECT_TYPE_SERVICE_PACKAGE_ITEM = 71;
export const OBJECT_TYPE_SERVICE_SALE = 72;
export const OBJECT_TYPE_SALE = 73;
export const OBJECT_TYPE_REAL_ESTATE_CITY = 74;
export const OBJECT_TYPE_REAL_ESTATE_AREA = 75;
export const OBJECT_TYPE_REAL_ESTATE_PROJECT = 76;
export const OBJECT_TYPE_REAL_ESTATE_PROPERTY = 77;
export const OBJECT_TYPE_REAL_ESTATE_INDIVIDUAL = 78;
export const OBJECT_TYPE_REAL_ESTATE_DEVELOPER = 79;
export const OBJECT_TYPE_REAL_ESTATE_PROJECT_TYPE = 80;
export const OBJECT_TYPE_REAL_ESTATE_PROPERTY_TYPE = 81;
export const OBJECT_TYPE_REAL_ESTATE_PROJECT_WEBSITE_ATTACHMENTS = 82;
export const OBJECT_TYPE_REAL_ESTATE_PROPERTY_WEBSITE_ATTACHMENTS = 83;
export const OBJECT_TYPE_ITEM_MEDIA = 84;
export const OBJECT_TYPE_ACTIVITY_BLUEPRINT = 85;
export const OBJECT_TYPE_ACTIVITY_STATUS = 86;
export const OBJECT_TYPE_ACTIVITY_MEMBER_TAG = 87;
export const OBJECT_TYPE_ACTIVITY_MEMBER_TAG_ASSIGNATION = 88;
export const OBJECT_TYPE_COMMENT = 89;
export const OBJECT_TYPE_ACTIVITY = 90;
export const OBJECT_TYPE_REAL_STATE_FEATURED_PROPERTY = 91;
export const OBJECT_TYPE_REAL_STATE_FEATURED_OFF_PLAN = 92;
export const OBJECT_TYPE_LEAD_SOURCE = 93;
export const OBJECT_TYPE_LEAD_TAG = 94;
export const OBJECT_TYPE_BOARD_VIEW_ITEM = 95;
export const OBJECT_TYPE_LEAD = 96;
export const OBJECT_TYPE_STUDENT = 97;
export const OBJECT_TYPE_TUTOR = 98;
export const OBJECT_TYPE_COURSE = 99;
export const OBJECT_TYPE_GUARDIAN = 100;
export const OBJECT_TYPE_COURSE_ENROLMENT = 101;
export const OBJECT_TYPE_PAYOUT = 102;
export const OBJECT_TYPE_PREDEFINED_CLASSES = 103;
export const OBJECT_TYPE_COMMISSION = 104;
export const OBJECT_TYPE_PRO_DESIGN_NODE = 105;
export const OBJECT_TYPE_PROPERTY_DEAL = 106;
export const OBJECT_TYPE_CINEMA = 107;
export const OBJECT_TYPE_MOVIE = 108;
export const OBJECT_TYPE_MOVIE_DISTRIBUTOR = 109;
export const OBJECT_TYPE_SEATING_PLAN = 110;
export const OBJECT_TYPE_THEATER_TYPE = 111;
export const OBJECT_TYPE_MOVIE_LANGUAGE = 112;
export const OBJECT_TYPE_MOVIE_GENRE = 113;
export const OBJECT_TYPE_MOVIE_TICKET = 114
export const OBJECT_TYPE_SHIPMENT = 115;
export const OBJECT_TYPE_PRODUCT_CLASSIFICATION = 116;
export const OBJECT_TYPE_SHIPMENT_PORT = 117;
export const OBJECT_TYPE_SHIPMENT_PACKAGE_TYPE = 118;
export const OBJECT_TYPE_SHIPMENT_MASTER = 119;
export const OBJECT_TYPE_SHIPMENT_ITEM_TYPE = 120;
export const OBJECT_TYPE_SHIPMENT_CONSIGNEE = 121;
export const OBJECT_TYPE_FUND_TRANSFER = 122;
export const OBJECT_TYPE_LECTURE = 123;
export const OBJECT_TYPE_LECTURE_VIDEO = 124;
export const OBJECT_TYPE_LECTURE_QUIZ = 125;
export const OBJECT_TYPE_LECTURE_QUIZ_QUESTION = 126;
export const OBJECT_TYPE_PRODUCT_TARGET = 127;
export const OBJECT_TYPE_SHIPMENT_LOADING_MODE_TYPE = 128;
export const OBJECT_TYPE_LMS_GROUP = 129;
export const OBJECT_TYPE_SYSTEM_VALUE = 130;
export const OBJECT_TYPE_REST_COMPONENT = 131;
export const OBJECT_TYPE_DELIVERY_LOCATION = 132;
export const OBJECT_TYPE_V2_CUSTOMER = 133;
export const OBJECT_TYPE_V2_VENDOR = 134;
export const OBJECT_TYPE_V2_EMPLOYEE = 135;
export const OBJECT_TYPE_V2_SALE = 136;
export const OBJECT_TYPE_V2_BILL = 137;
export const OBJECT_TYPE_SALES_WORK_INVENTORY_STATUS = 138;
export const OBJECT_TYPE_SHIPMENT_DELIVERY_AGENT = 139;
export const OBJECT_TYPE_COUPON_PROMOTION = 140;
export const OBJECT_TYPE_PRINTER = 141;
export const OBJECT_TYPE_DELIVERY_ZONE = 142;
export const OBJECT_TYPE_DELIVERY_CITY = 143;
export const OBJECT_TYPE_INVENTORY_BATCH_NO = 144;
export const OBJECT_TYPE_FNB_EVENT_SALON = 145;
export const OBJECT_TYPE_FNB_EVENT_MENU = 146;
export const OBJECT_TYPE_FNB_EVENT_SERVICE = 147;
export const OBJECT_TYPE_FNB_EVENT = 148;


export const getObjectTypeUrl = objectType => {
    switch (objectType) {
        case OBJECT_TYPE_ACCOUNT:
            return "/staff-users";
        case OBJECT_TYPE_SUPPLIER:
            return "/suppliers";
        case OBJECT_TYPE_CUSTOMER:
            return "/customers";
        case OBJECT_TYPE_WAREHOUSE:
            return "/warehouses"
        case OBJECT_TYPE_STORE:
            return "/stores";
        case OBJECT_TYPE_PRODUCT:
            return "/products";
        case OBJECT_TYPE_PRODUCT_BUNDLE:
            return "/product-bundles";
        case OBJECT_TYPE_LOYALTY_CARD_ISSUE:
            return "/loyalty-cards-issue";
        case OBJECT_TYPE_COUPON:
            return "/coupons"
        case OBJECT_TYPE_CHEQUE:
            return "/cheques"
        case OBJECT_TYPE_SUPPORTED_CURRENCY:
            return "/supported-currencies"
        case OBJECT_TYPE_PRODUCT_DEPARTMENT:
            return "/product-departments"
        case OBJECT_TYPE_PRODUCT_SUBCATEGORY:
            return "/product-subcategories"
        case OBJECT_TYPE_PRODUCT_BRAND:
            return "/product-brands"
        case OBJECT_TYPE_TEMPLATE:
            return "/templates"
        case OBJECT_TYPE_MOBILE_UNIT:
            return "/mobile-units"
        case OBJECT_TYPE_SALES_CART_TYPE:
            return "/sales-cart-types"
        case OBJECT_TYPE_PRODUCT_ATTRIBUTES:
            return "/product-attribute-packs";
        case OBJECT_TYPE_PRODUCT_GROUP:
            return "/product-groups"
        case OBJECT_TYPE_SALES_ORDER_STATE:
            return "/sales-order-states"
        case OBJECT_TYPE_ONLINE_STORE_PAYMENT_METHOD:
            return "/online-store-payment-methods"
        case OBJECT_TYPE_ONLINE_STORE_CUSTOM_PAGE:
            return "/online-store-custom-pages"
        case OBJECT_TYPE_BLOG_CATEGORY:
            return "/blog-categories";
        case OBJECT_TYPE_BLOG_POST:
            return "/blog-posts";
        case OBJECT_NEWSLETTER_SUBSCRIPTION:
            return "/newsletter-subscriptions"
        case OBJECT_TYPE_SLIDE:
            return "/slides"

        case OBJECT_TYPE_DESIGNATION:
            return "/designations"
        case OBJECT_TYPE_LEAVE_TYPE:
            return "/leave-types"
        case OBJECT_TYPE_PAY_HEAD:
            return "/pay-heads"
        case OBJECT_TYPE_SALARY_PACKAGE:
            return "/salary-packages"
        case OBJECT_TYPE_EMPLOYEE:
            return "/employees"
        case OBJECT_TYPE_LEAVE:
            return "/leaves"
        case OBJECT_TYPE_VENDOR:
            return "/vendors"
        case OBJECT_TYPE_PAGE_HEAD:
            return "/page-head"
        case OBJECT_TYPE_VENUE:
            return "/venues"

        case OBJECT_TYPE_SERVICE_DEPARTMENT:
            return "/service-departments"
        case OBJECT_TYPE_SERVICE_CATEGORY:
            return "/service-categories"
        case OBJECT_TYPE_SERVICE_SUBCATEGORY:
            return "/service-subcategories"
        case OBJECT_TYPE_SERVICE:
            return "/services"
        case OBJECT_TYPE_SERVICE_PACKAGE:
            return "/service-packages"

        case OBJECT_TYPE_REAL_ESTATE_CITY:
            return "/real-estate-city";
        case OBJECT_TYPE_REAL_ESTATE_AREA:
            return "/real-estate-area";
        case OBJECT_TYPE_REAL_ESTATE_PROJECT:
            return "/real-estate-project";
        case OBJECT_TYPE_REAL_ESTATE_PROPERTY:
            return "/real-estate-property";
        case OBJECT_TYPE_REAL_ESTATE_INDIVIDUAL:
            return "/real-estate-individual";
        case OBJECT_TYPE_REAL_ESTATE_DEVELOPER:
            return "/real-estate-developer";
        case OBJECT_TYPE_REAL_ESTATE_PROJECT_TYPE:
            return "/real-estate-project-type";
        case OBJECT_TYPE_REAL_ESTATE_PROPERTY_TYPE:
            return "/real-estate-property-type";


        case OBJECT_TYPE_ACTIVITY_BLUEPRINT:
            return "/activity-blueprints"
        case OBJECT_TYPE_ACTIVITY_STATUS:
            return "/activity-statuses"
        case OBJECT_TYPE_ACTIVITY_MEMBER_TAG:
            return "/activity-member-tags"
        case OBJECT_TYPE_ACTIVITY:
            return "/activities";

        case OBJECT_TYPE_REAL_STATE_FEATURED_PROPERTY:
            return "/featured-properties";
        case OBJECT_TYPE_REAL_STATE_FEATURED_OFF_PLAN:
            //return "/featured-off-plans";
            return "/featured-projects";
        case OBJECT_TYPE_LEAD_SOURCE:
            return "/lead-sources";
        case OBJECT_TYPE_LEAD_TAG:
            return "/lead-tags";

        case OBJECT_TYPE_STUDENT:
            return "/students";
        case OBJECT_TYPE_TUTOR:
            return "/tutors";
        case OBJECT_TYPE_COURSE:
            return "/courses";
        case OBJECT_TYPE_GUARDIAN:
            return "/guardians";
        case OBJECT_TYPE_COURSE_ENROLMENT:
            return '/course-enrolments';
        case OBJECT_TYPE_PREDEFINED_CLASSES:
            return '/predefined-classes'
        case OBJECT_TYPE_COMMISSION:
            return '/commissions'
        case OBJECT_TYPE_PROPERTY_DEAL:
            return '/property-deals'


        case OBJECT_TYPE_CINEMA:
            return "/cinemas";
        case OBJECT_TYPE_MOVIE:
            return "/movies";
        case OBJECT_TYPE_MOVIE_DISTRIBUTOR:
            return "/movie-distributors";
        case OBJECT_TYPE_SEATING_PLAN:
            return "/theater-designs";
        case OBJECT_TYPE_THEATER_TYPE:
            return "/theater-types";
        case OBJECT_TYPE_MOVIE_LANGUAGE:
            return "/movie-languages";
        case OBJECT_TYPE_MOVIE_GENRE:
            return "/movie-genres";

        case OBJECT_TYPE_PRODUCT_CLASSIFICATION:
            return "/product-classifications"

        case OBJECT_TYPE_SHIPMENT_PORT:
            return "/shipment-ports"
        case OBJECT_TYPE_SHIPMENT_PACKAGE_TYPE:
            return "/shipment-package-types"

        case OBJECT_TYPE_SHIPMENT_ITEM_TYPE:
            return "/shipment-item-types"
        case OBJECT_TYPE_SHIPMENT_CONSIGNEE:
            return "/shipment-consignees"

        case OBJECT_TYPE_LECTURE:
            return "/lectures";
        case OBJECT_TYPE_LECTURE_VIDEO:
            return "/lecture-videos";
        case OBJECT_TYPE_LECTURE_QUIZ:
            return "/lecture-quizzes";

        case OBJECT_TYPE_PRODUCT_TARGET:
            return "/product-targets"
        case OBJECT_TYPE_SHIPMENT_LOADING_MODE_TYPE:
            return "/shipment-loading-mode-types"

        case OBJECT_TYPE_LMS_GROUP:
            return "/lms-groups"

        case OBJECT_TYPE_REST_COMPONENT:
            return "/rest-components"

        case OBJECT_TYPE_DELIVERY_LOCATION:
            return "/delivery-locations"

        case OBJECT_TYPE_SALES_WORK_INVENTORY_STATUS:
            return "/sales-work-inventory-statuses"

        case OBJECT_TYPE_SHIPMENT_DELIVERY_AGENT:
            return "/shipment-delivery-agents";

        case OBJECT_TYPE_COUPON_PROMOTION:
            return "/coupon-promotions"

        case OBJECT_TYPE_PRINTER:
            return "/printers"

        case OBJECT_TYPE_DELIVERY_ZONE:
            return "/delivery-zones";
        case OBJECT_TYPE_DELIVERY_CITY:
            return "/delivery-cities"
        case OBJECT_TYPE_INVENTORY_BATCH_NO:
            return "/inventory-batch-nos"

        case OBJECT_TYPE_FNB_EVENT_SALON:
            return "/event-salon";
        case OBJECT_TYPE_FNB_EVENT_MENU:
            return "/event-menu";
        case OBJECT_TYPE_FNB_EVENT_SERVICE:
            return "/event-service-equipment";
    }
}

export const getObjectTypeName = objectType => {
    switch (objectType) {
        case OBJECT_TYPE_ACCOUNT:
            return "Staff User";
        case OBJECT_TYPE_PRODUCT_CATEGORY:
            return "Category";
        case OBJECT_TYPE_STORE:
            return "Store";
        case OBJECT_TYPE_TERMINAL:
            return "Terminal";
        case OBJECT_TYPE_PRODUCT:
            return "Product";
        case OBJECT_TYPE_PRODUCT_BUNDLE:
            return "Bundle";
        case OBJECT_TYPE_LOYALTY_CARD:
            return "Loyalty Card";
        case OBJECT_TYPE_LOYALTY_CARD_ISSUE:
            return "Loyalty Card Issue";
        case OBJECT_TYPE_CUSTOMER:
            return "Customer";
        case OBJECT_TYPE_SUPPLIER:
            return "Supplier";
        case OBJECT_TYPE_CUSTOMER_GROUP:
            return "Customer Group";
        case OBJECT_TYPE_COUPON:
            return "Coupon";
        case OBJECT_TYPE_DISCOUNT:
            return "Discount";
        case OBJECT_TYPE_CONTACT:
            return "Contact";
        case OBJECT_TYPE_WAREHOUSE:
            return "Warehouse";
        case OBJECT_TYPE_GROUP_PRICE_OVERRIDE:
            return "Group Price Override";
        case OBJECT_TYPE_PRICE_OVERRIDE:
            return "Price Override";
        case OBJECT_TYPE_ACCOUNT_LEDGER_GROUP:
            return "Account Ledger Group";
        case OBJECT_TYPE_ACCOUNT_LEDGER:
            return "Account Ledger";
        case OBJECT_TYPE_TAX:
            return "Tax";
        case OBJECT_TYPE_CHEQUE:
            return "Cheque";
        case OBJECT_TYPE_SUPPORTED_CURRENCY:
            return "Supported Currency";
        case OBJECT_TYPE_PRODUCT_DEPARTMENT:
            return "Department";
        case OBJECT_TYPE_PRODUCT_SUBCATEGORY:
            return "Subcategory";
        case OBJECT_TYPE_PRODUCT_BRAND:
            return "Brand";
        case OBJECT_TYPE_TEMPLATE:
            return "Template";
        case OBJECT_TYPE_PRODUCT_BUNDLE_ITEM:
            return "Product Bundle Item";
        case OBJECT_TYPE_MOBILE_UNIT:
            return "Mobile Unit";
        case OBJECT_TYPE_MEASUREMENT_TYPE:
            return "Measurement Type";
        case OBJECT_TYPE_SALES_CART_TYPE:
            return "Sales Cart Type"
        case OBJECT_TYPE_PACKAGE_UNIT:
            return "Package Unit";
        case OBJECT_TYPE_PRODUCT_ATTRIBUTES:
            return "Product Attribute Pack";
        case OBJECT_TYPE_PRODUCT_GROUP:
            return "Product Group";
        case OBJECT_TYPE_SALES_ORDER_STATE:
            return "Sales Order State";
        case OBJECT_TYPE_ONLINE_STORE_PAYMENT_METHOD:
            return "Online Store Payment Method";
        case OBJECT_TYPE_ONLINE_STORE_CUSTOM_PAGE:
            return "Online Store Custom Page";
        case OBJECT_TYPE_BLOG_CATEGORY:
            return "Blog Category";
        case OBJECT_TYPE_BLOG_POST:
            return "Blog Post";
        case OBJECT_NEWSLETTER_SUBSCRIPTION:
            return "Newsletter Subscription"
        case OBJECT_TYPE_SLIDE:
            return "Slide";

        case OBJECT_TYPE_DESIGNATION:
            return "Designation";
        case OBJECT_TYPE_LEAVE_TYPE:
            return "Leave Type";
        case OBJECT_TYPE_PAY_HEAD:
            return "Pay Head";
        case OBJECT_TYPE_SALARY_PACKAGE:
            return "Salary Package";
        case OBJECT_TYPE_EMPLOYEE:
            return "Employee";
        case OBJECT_TYPE_LEAVE:
            return "Leave";
        case OBJECT_TYPE_VENDOR:
            return "Vendor";
        case OBJECT_TYPE_CUSTOM_PRICE:
            return "Price Rule"
        case OBJECT_TYPE_PAGE_HEAD:
            return "Page Head";
        case OBJECT_TYPE_MOBILE_UNIT_DRIVER_ASSIGNATION:
            return "Mobile Unit Driver Assignation"
        case OBJECT_TYPE_ALLOWED_PRODUCT:
            return "Allowed Product";
        case OBJECT_TYPE_VENUE:
            return "Venue";
        case OBJECT_TYPE_GALLERY_POST:
            return "Gallery Post";
        case OBJECT_TYPE_TESTIMONY_POST:
            return "Testimony Post";

        case OBJECT_TYPE_SERVICE_DEPARTMENT:
            return "Service Department";
        case OBJECT_TYPE_SERVICE_CATEGORY:
            return "Service Category";
        case OBJECT_TYPE_SERVICE_SUBCATEGORY:
            return "Service Subcategory";
        case OBJECT_TYPE_SERVICE:
            return "Service";

        case OBJECT_TYPE_SERVICE_PACKAGE:
            return "Package";
        case OBJECT_TYPE_SERVICE_PACKAGE_ITEM:
            return "Service Package Item";

        case OBJECT_TYPE_REAL_ESTATE_CITY:
            return "City";
        case OBJECT_TYPE_REAL_ESTATE_AREA:
            return "Area";
        case OBJECT_TYPE_REAL_ESTATE_PROJECT:
            return "Project";
        case OBJECT_TYPE_REAL_ESTATE_PROPERTY:
            return "Property";
        case OBJECT_TYPE_REAL_ESTATE_INDIVIDUAL:
            return "Owner/Tenant";
        case OBJECT_TYPE_REAL_ESTATE_DEVELOPER:
            return "Developer";
        case OBJECT_TYPE_REAL_ESTATE_PROJECT_TYPE:
            return "Project Type";
        case OBJECT_TYPE_REAL_ESTATE_PROPERTY_TYPE:
            return "Property Type";
        case OBJECT_TYPE_ITEM_MEDIA:
            return "Media Item";

        case OBJECT_TYPE_ACTIVITY_BLUEPRINT:
            return "Activity Blueprint"
        case OBJECT_TYPE_ACTIVITY_STATUS:
            return "Activity Status";
        case OBJECT_TYPE_ACTIVITY_MEMBER_TAG:
            return "Member Tag"
        case OBJECT_TYPE_ACTIVITY_MEMBER_TAG_ASSIGNATION:
            return "Member Tag Assignation"
        case OBJECT_TYPE_ACTIVITY:
            return "Activity";

        case OBJECT_TYPE_REAL_STATE_FEATURED_PROPERTY:
            return "Featured Property";
        case OBJECT_TYPE_REAL_STATE_FEATURED_OFF_PLAN:
            //return "Featured Off Plan";
            return "Featured Projects";
        case OBJECT_TYPE_LEAD_SOURCE:
            return "Lead Source";
        case OBJECT_TYPE_LEAD_TAG:
            return "Lead Tag";

        case OBJECT_TYPE_STUDENT:
            return "Student";
        case OBJECT_TYPE_TUTOR:
            return "Tutor";
        case OBJECT_TYPE_COURSE:
            return "Course";
        case OBJECT_TYPE_GUARDIAN:
            return "Guardian";
        case OBJECT_TYPE_COURSE_ENROLMENT:
            return "Course Enrolment"
        case OBJECT_TYPE_PREDEFINED_CLASSES:
            return "Predefined Classes";
        case OBJECT_TYPE_COMMISSION:
            return 'Commission'
        case OBJECT_TYPE_PROPERTY_DEAL:
            return 'Property Deal'

        case OBJECT_TYPE_CINEMA:
            return "Cinemas";
        case OBJECT_TYPE_MOVIE:
            return "Movies";
        case OBJECT_TYPE_MOVIE_DISTRIBUTOR:
            return "Movie Distributors";
        case OBJECT_TYPE_SEATING_PLAN:
            return "Theater Designs";
        case OBJECT_TYPE_THEATER_TYPE:
            return "Theater Blueprints";
        case OBJECT_TYPE_MOVIE_LANGUAGE:
            return "Movie Languages";
        case OBJECT_TYPE_MOVIE_GENRE:
            return "Movie Genres";

        case OBJECT_TYPE_PRODUCT_CLASSIFICATION:
            return "Classification";

        case OBJECT_TYPE_SHIPMENT_PORT:
            return "Port"
        case OBJECT_TYPE_SHIPMENT_PACKAGE_TYPE:
            return "Package Type"

        case OBJECT_TYPE_SHIPMENT_ITEM_TYPE:
            return "Item Type";
        case OBJECT_TYPE_SHIPMENT_CONSIGNEE:
            return "Consignee";

        case OBJECT_TYPE_LECTURE:
            return "Course";
        case OBJECT_TYPE_LECTURE_VIDEO:
            return "Course Video";
        case OBJECT_TYPE_LECTURE_QUIZ:
            return "Course Quiz";

        case OBJECT_TYPE_PRODUCT_TARGET:
            return "Product Target"
        case OBJECT_TYPE_SHIPMENT_LOADING_MODE_TYPE:
            return "Loading Mode Type"

        case OBJECT_TYPE_LMS_GROUP:
            return "Group";


        case OBJECT_TYPE_REST_COMPONENT:
            return "Restaurant Component";

        case OBJECT_TYPE_DELIVERY_LOCATION:
            return "Delivery Location";

        case OBJECT_TYPE_SALES_WORK_INVENTORY_STATUS:
            return "Sales Work Inventory Status"

        case OBJECT_TYPE_SHIPMENT_DELIVERY_AGENT:
            return "Delivery Agent";

        case OBJECT_TYPE_COUPON_PROMOTION:
            return "Coupon Promotion";

        case OBJECT_TYPE_PRINTER:
            return "Printer";

        case OBJECT_TYPE_DELIVERY_ZONE:
            return "Delivery Zone";
        case OBJECT_TYPE_DELIVERY_CITY:
            return "Delivery City"
        case OBJECT_TYPE_INVENTORY_BATCH_NO:
            return "Inventory Batch No"

        case OBJECT_TYPE_FNB_EVENT_SALON:
            return "Event Salon";
        case OBJECT_TYPE_FNB_EVENT_MENU:
            return "Event Menu";
        case OBJECT_TYPE_FNB_EVENT_SERVICE:
            return "Event Service/Equipment";
    }
}