
import { CaretRight16, Code16, Add16 } from '@carbon/icons-react'
import { useState } from 'react'

export function SideBar({ children, visible }) {
    return (
        <div className="robo-ide-side-bar" style={{ width: visible ? 300 : 0, minWidth: visible ? 300 : 0, opacity: visible ? 1 : 0, transition: 'width 250ms, min-width 250ms, opacity 250ms' }}>
            {children}
        </div>
    )
}

export function SideBarGroup({ title, children }) {
    const [expanded, setExpanded] = useState(false);

    return <>
        <div className='side-bar-group'>
            <div className="side-bar-opt" onClick={() => setExpanded(p => !p)}>
                <CaretRight16 className="arrow-handler" data-expanded={expanded} />
                {title}
            </div>

            <div className='side-bar-group-content'>
                {expanded && children}
            </div>
        </div>
    </>
}

export function SideBarItem({ title, selected, onClick }) {
    return (
        <div className="side-bar-opt" onClick={onClick} data-selected={selected}>
            <Code16 />
            {title}
        </div>
    )
}

export function SideBarAdd({ onClick }) {
    return (
        <div className="side-bar-opt" onClick={onClick} style={{ opacity: 0.65 }}>
            <Add16 />
            <span style={{ fontSize: 12, }}>
                Create New
            </span>

        </div>
    )
}