import {
    AddFilled20, Copy16, TrashCan16, TextFont32
} from '@carbon/icons-react'
import { Tag } from 'carbon-components-react'
import ImageView from '../../components/ImageView'
import Api from '../../session/Api'
import Button from '../../components/Button'
import { copyToClipboard } from '../../pages/monitoring/TerminalMonitoringView'
import UIUtil from '../../util/UIUtil'

const ImageItem = ({ }) => {
    const name = 'mymainlogo'

    const onDeleteBtn = () => {
        UIUtil.confirm(() => {
            UIUtil.showError("Not authorized");
        })
    }

    const onCopyBtn = () => {
        const code = `robo.res("${name}")`
        copyToClipboard("${" + code + "}");
    }

    return (
        <div className='robo-res-item' style={{}}>
            <div style={{ width: '100%', height: 300 }}>
                <ImageView
                    style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }}
                    imageViewStyle={{ objectFit: 'cover', objectPosition: 'center' }}
                    src={Api.getThumbnail()} />
            </div>

            <div style={{ padding: '1rem', display: 'flex', alignItems: 'center', width: '100%', gap: '0.5rem' }}>
                <div style={{ flex: 1 }}>
                    <p>{name}</p>
                    <Tag size='sm' type='blue'>Image</Tag>
                </div>

                <Button onClick={onDeleteBtn} hasIconOnly renderIcon={TrashCan16} kind="danger--ghost" iconDescription="Delete" style={{ borderRadius: 7 }} />
                <Button onClick={onCopyBtn} hasIconOnly renderIcon={Copy16} iconDescription="Copy Code" style={{ borderRadius: 7 }} />
            </div>
        </div>
    )
}


const FontItem = () => {
    const name = 'mymainlogo'

    const onDeleteBtn = () => {
        UIUtil.confirm(() => {
            UIUtil.showError("Not authorized");
        })
    }

    const onCopyBtn = () => {
        const code = `robo.res("${name}")`
        copyToClipboard("${" + code + "}");
    }

    return (
        <div className='robo-res-item' style={{}}>
            <div style={{ width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#171717' }}>
                <TextFont32 />
            </div>

            <div style={{ padding: '1rem', display: 'flex', alignItems: 'center', width: '100%', gap: '0.5rem' }}>
                <div style={{ flex: 1 }}>
                    <p>fewfwefe</p>
                    <Tag size='sm' type='green'>Font</Tag>
                </div>

                <Button onClick={onDeleteBtn} hasIconOnly renderIcon={TrashCan16} kind="danger--ghost" iconDescription="Delete" style={{ borderRadius: 7 }} />
                <Button onClick={onCopyBtn} hasIconOnly renderIcon={Copy16} iconDescription="Copy Code" style={{ borderRadius: 7 }} />
            </div>
        </div>
    )
}


const CreateBtn = () => {
    return (
        <div className='robo-res-item create-res-btn' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 300 }}>
            <AddFilled20 />
        </div>
    )
}




export function Resources() {

    return (
        <div style={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, minmax(0, 1fr))', }}>
                <ImageItem />
                <FontItem />
                <FontItem />
                <ImageItem />
                <ImageItem />
                <ImageItem />

                <CreateBtn />
            </div>
        </div>
    )
}
