
import Editor from "@monaco-editor/react";
import { Code16, CheckmarkFilled16, WarningAltFilled16, Locked16 } from '@carbon/icons-react'
import { InlineLoading, Tag } from "carbon-components-react";
import { useSaveShortcut } from "../hooks/use-save-shortcut";
import UIUtil from "../../util/UIUtil";
import { useEffect, useMemo, useState } from "react";
import Api from "../../session/Api";

export function CodeEditor({ lang, selected, onSaved }) {
    const [loading, setLoading] = useState(false);

    const [value, setValue] = useState("");
    const [originalValue, setOriginalValue] = useState(false);

    //const readonly = !selected?.id;
    const readonly = selected?.readonly;

    const changesMade = useMemo(() => value !== originalValue, [value, originalValue])

    useSaveShortcut(async () => {
        if (!changesMade) {
            return;
        }

        const stopLoading = await UIUtil.pageLoader();
        try {
            const [success] = await Api.try((api, listener) => api.saveMarkupTemplateSrc(selected.path, value, listener));
            if (!success) {
                return;
            }

            setOriginalValue(value);
            onSaved?.();
        } finally {
            stopLoading();
        }
    }, [value])


    useEffect(() => {
        setValue("")
        setOriginalValue("");

        if (!selected?.path) {
            setLoading(false);
            return;
        }

        let cancelled = false;
        setLoading(true);
        Api.loadMarkupTemplateSrc(selected.path, response => {
            if (cancelled) {
                return;
            }

            if (response.status === true) {
                setLoading(false);
                setValue(response.payload);
                setOriginalValue(response.payload);
            } else {
                UIUtil.showError(response.message);
            }
        })

        return () => cancelled = true;
    }, [selected?.path])


    const loader = (
        <div className="robo-ide-editor centered-progress-bar" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#171717' }}>
            <InlineLoading style={{ width: 'unset' }} />
            <p style={{ fontSize: 12, opacity: 0.65, color: 'white', textAlign: 'center' }}>Loading...</p>
        </div>
    );


    if (loading) {
        return loader;
    }


    if (!selected) {
        return (
            <div className="robo-ide-editor" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#171717' }}>
                <p style={{ fontSize: 12, opacity: 0.65, color: 'white', textAlign: 'center' }}>Nothing selected</p>
            </div>
        )
    }

    return (
        <div className="robo-ide-editor">
            <div className="robo-ide-editor-status-bar">
                <Code16 />
                {selected.name}


                {readonly ? (
                    <div className="readonly-message"><Locked16 /> Read Only</div>
                ) : changesMade ? (
                    <div className="unsaved-changes-warning"><WarningAltFilled16 /> Unsaved Changes!</div>
                ) : (
                    <div className="changes-saved-message"><CheckmarkFilled16 /> Changes Saved</div>
                )}
            </div>
            <div style={{ width: '100%', height: 'calc(100% - 2rem)' }}>
                <Editor
                    value={value}
                    onChange={readonly ? undefined : setValue}
                    theme="vs-dark"
                    language={lang}
                    loading={loader}
                    options={{
                        readOnly: readonly,
                        "bracketPairColorization.enabled": true
                    }}
                />
            </div>
        </div>
    )
}