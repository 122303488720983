import "../styles.scss";

import { useCallback, useMemo, useState } from "react";
import { withLoadablePage } from "../../base/Page"
import Api from "../../session/Api"
import { Toolbar } from "../components/toolbar";
import { SideBar, SideBarAdd, SideBarGroup, SideBarItem } from "../components/side-bar";
import { CodeEditor } from "../components/code-editor";
import { Preview } from "../components/preview";
import UIUtil from "../../util/UIUtil";
import Util from "../../util/Util";
import { useForceLockScroll } from "../../hooks/useLockScroll";
import { Resources } from "../components/resources";


function TemplatesTab({ definitions, showSidebar, selected, setSelected, sideView, createNewTemplate }) {
    const [previewKey, setPreviewKey] = useState(() => Util.newTempId())
    const selectedDef = useMemo(() => definitions.find(def => def.templates.includes(selected)), [definitions, selected])

    const onSaved = useCallback(() => {
        setPreviewKey(Util.newTempId())
    }, [])

    return (
        <div className="robo-ide-root">
            <SideBar visible={showSidebar}>
                {definitions.map(definition => (
                    <SideBarGroup key={definition.name} title={definition.title}>
                        {definition.templates.map(template => (
                            <SideBarItem
                                key={template.path}
                                title={template.name}
                                selected={selected?.path === template.path}
                                onClick={() => setSelected(template)}
                            />
                        ))}

                        <SideBarAdd key={definition.name + 'add'} onClick={() => createNewTemplate(definition)} />
                    </SideBarGroup>
                ))}
            </SideBar>

            <CodeEditor lang="html" selected={selected} onSaved={onSaved} />

            {sideView === "preview" &&
                <Preview key={previewKey} definition={selectedDef} selected={selected} />}
        </div>
    )
}


function ResourcesTab({ }) {
    return (
        <div className="robo-ide-resources-tab">
            <Resources />
        </div>
    )
}


function FragmentsTab({ showSidebar, fragments, selectedFrag, setSelectedFrag, createNewFragment }) {
    return (
        <div className="robo-ide-root">
            <SideBar visible={showSidebar}>
                {fragments.map(template => (
                    <SideBarItem
                        key={template.path}
                        title={template.name}
                        selected={selectedFrag?.path === template.path}
                        onClick={() => setSelectedFrag(template)}
                    />
                ))}
                <SideBarAdd onClick={() => createNewFragment()} />
            </SideBar>

            <CodeEditor lang="html" selected={selectedFrag} />
        </div>
    )
}


function ConstantsTab({ }) {
    const selected = useMemo(() => ({
        name: "Constants",
        path: "constants",
    }), [])

    return (
        <div className="robo-ide-constants-tab">
            <CodeEditor lang="json" selected={selected} />
        </div>
    )
}

function Tab({ index, ...props }) {
    switch (index) {
        case 0:
            return <TemplatesTab {...props} />
        case 1:
            return <ResourcesTab {...props} />
        case 2:
            return <FragmentsTab {...props} />
        case 3:
            return <ConstantsTab {...props} />
        default:
            return null;
    }
}

function View({ payload }) {
    const [tabIndex, setTabIndex] = useState(0);
    const [sideView, setSideView] = useState("");

    const [definitions, setDefinitions] = useState(payload.definitions);
    const [fragments, setFragments] = useState(payload.fragments);

    const [showSidebar, setShowSidebar] = useState(true);

    const [selected, setSelected] = useState(undefined);
    const [selectedFrag, setSelectedFrag] = useState(undefined);

    const createNewTemplate = useCallback(async definition => {
        const stopLoading = await UIUtil.pageLoader();
        try {
            const [success, response] = await Api.try((api, listener) => api.createMarkupTemplate(definition.name, listener));
            if (!success) {
                return;
            }

            setDefinitions(prevDefs => prevDefs.map(prev => prev.name === definition.name ? {
                ...prev,
                templates: [
                    ...prev.templates,
                    {
                        name: response.name,
                        path: response.src,
                    }
                ]
            } : definition))
        } finally {
            stopLoading();
        }
    }, []);

    const createNewFragment = useCallback(async () => {
        const stopLoading = await UIUtil.pageLoader();
        try {
            const [success, response] = await Api.try((api, listener) => api.createMarkupTemplate('fragment', listener));
            if (!success) {
                return;
            }

            setFragments(prev => [
                ...prev,
                {
                    name: response.name,
                    path: response.src,
                }
            ])
        } finally {
            stopLoading();
        }
    }, []);

    const updateTemplateName = useCallback((definition, template, newName) => setDefinitions(prevDefs => prevDefs.map(prev => prev.name === definition.name ? {
        ...prev,
        templates: prev.templates.map(templ => templ.path === template.path ? {
            ...templ, name: newName
        } : templ)
    } : definition)), []);

    useForceLockScroll(true)

    return (
        <div style={{ background: '#1c1c1c' }}>
            <Toolbar
                tabIndex={tabIndex} setTabIndex={setTabIndex}
                sideView={sideView} setSideView={setSideView}
                onSidebarBtn={() => setShowSidebar(p => !p)} />

            <Tab index={tabIndex} {...{
                definitions, fragments,
                showSidebar, sideView,
                selectedFrag, setSelectedFrag,
                selected, setSelected,
                createNewTemplate, updateTemplateName,
                createNewFragment
            }} />
        </div>
    )
}

export const MarkupTemplatesPage = withLoadablePage(Api.getMarkupTemplates.bind(Api), View);